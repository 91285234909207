<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-app id="login-home">
    <v-main>
        <div style="width:100vw;height:100vh;display:flex;justify-content:center;align-items:center;">
            Autenticando Usuario... {{idusuario}}
        </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
    data: () => ({
        idusuario:null,
        modo:"0"
    }),
    components:{
       
    },
    methods: {
       
    },
    mounted() {

        

        this.idusuario = this.$route.params.idusuario
        this.modo = this.$route.params.modo
        this.$http.post("/perfil/usuario/selectAuthSimples", { token:'3c8478c895580214e0ff389448054854',id: this.idusuario })
        .then((response) => {
            if(response.status == 200 && response.data 
                    && response.data.length > 0 && response.data[0].email){
                
                this.perfil = response.data[0];
                
                localStorage.setItem('useremail',this.perfil.email)
                localStorage.setItem('apelido',this.perfil.apelido)
                this.$router.push(`/capturar/${this.modo}`)
                
            }
            
        })
        .catch((error) => {
            alert(error)
            console.log(error)
        });


        // localStorage.setItem('token', this.token)
        // localStorage.setItem('x-access-token', this.token)
        // axios.defaults.headers.common['x-access-token'] = this.token
        // this.$route.push('')
    },
};
</script>

<style>
</style>
